<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="false" />
    <v-snackbar v-model="showSnackBar" color="#00a972" right :timeout="timeout">
      {{msg}}
      <v-btn text @click="showSnackBar = false">
        <v-icon style="color:white;">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12 md12 pa-0>
        <v-card id="app_height" outlined tile>
          <v-layout wrap justify-space-around>
            <v-flex xs12 md12 pa-8>
              <v-layout wrap>
                <v-flex xs12 md12 text-center>
                  <span style="font-size:20px;font-weight:500;">Application Users List</span>
                </v-flex>
              </v-layout>
              <v-layout wrap px-2 justify-center>
                <v-flex xs12 sm12 md12>
                  <v-card-title>
                    <v-layout wrap justify-space-around align="center">
                      <v-flex xs12 sm6 md3 pt-3>
                        <span style="font-size:14px;font-weight:600">Download Data:</span>
                        <v-btn text color="#26af82">
                          <download-csv :data="users" name="Users.csv">CSV</download-csv>
                        </v-btn>

                        <a :href="baseURL+'/user/pdfuserlist?type='+type" download>
                          <v-btn text color="red">PDF</v-btn>
                        </a>
                      </v-flex>
                      <!-- <v-btn text color="red" @click="downloadFile">Download</v-btn> -->

                      <v-flex xs12 sm6 md3>
                        <v-select
                          :items="userTypes"
                          ref="country"
                          v-model="userType"
                          label="User Type"
                          color="#26af82"
                          append-icon="mdi-account"
                        ></v-select>
                      </v-flex>
                      <!-- <v-flex xs12 sm6 md3>
                        <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          color="#26af82"
                          label="Search"
                        ></v-text-field>
                      </v-flex>-->
                    </v-layout>
                  </v-card-title>

                  <!-- <v-data-table
                    :headers="headers"
                    :items="users"
                    :search="search"
                    :sort-desc="[false, true]"
                    hide-default-footer
                    dense
                    :loading="appLoading"
                    loading-text="Loading... Please wait"
                    class="elevation-1"
                    color="#26af82"
                  >
                    <template v-slot:item.address1="{ item }">
                      <span>{{item.address1}},&nbsp;</span>
                      <span>{{item.city}},&nbsp;</span>
                      <span>{{item.postcode}}</span>
                    </template>
                    <template v-slot:item.phone="{ item }">
                      <span>{{item.phonePrefix}}</span>
                      <span>{{item.phone}}</span>
                    </template>
                    <template v-slot:item._id="{ item }">
                      <router-link :to="'/ViewUser/'+item._id">
                        <v-btn small text color="#26af82" block>View</v-btn>
                      </router-link>
                    </template>
                  </v-data-table>-->

                  <!-- <v-layout wrap justify-center>
                    <v-flex xs12 sm12 md12 pt-2>
                      <v-card>
                        <v-layout pt-2 wrap style="align-items:center" justify-center>
                          <v-flex xs2 sm2 md2 text-right>
                            <span v-if="page>1">
                              <v-btn @click="prevpage" small text color="#26af82">Prev</v-btn>
                            </span>
                          </v-flex>
                          <v-flex xs2 sm2 md1 text-center>
                            <v-text-field
                              v-model="page"
                              color="#26af82"
                              min="1"
                              :dense="true"
                              :max="totalpages"
                              type="number"
                              class="centered-input"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs2 sm2 md2 text-left>
                            <span v-if="page<totalpages">
                              <v-btn @click="nextpage" small text color="#26af82">Next</v-btn>
                            </span>
                          </v-flex>
                        </v-layout>
                        <v-layout wrap pt-2 style="align-items:center" justify-center>
                          <v-flex xs6 sm6 md2 text-left>Items Per Page</v-flex>
                          <v-flex xs6 sm6 md1>
                            <v-select
                              v-model="itemsPerPage"
                              :items="ItemsCount"
                              color="#26af82"
                              required
                            ></v-select>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>-->
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import VueElementLoading from "vue-element-loading";
import axios from "axios";
export default {
  components: {
    VueElementLoading
  },
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      timeout: 3000,
      msg: null,
      search: null,
      headers: [
        {
          text: "Username",
          align: "left",
          sortable: true,
          value: "username"
        },
        { text: "Type", value: "type" },
        { text: "Phone", value: "phone" },
        { text: "Email ID", value: "email" },
        { text: "Address", value: "address1" },
        { text: "Account Created", value: "accountCreateAt" },
        { text: "Action", align: "center", value: "_id" }
      ],
      userType: "All",
      userTypes: ["All", "Individual", "Company", "Admin"],
      users: [],
      itemsPerPage: 50,
      page: 1,
      totalpages: 0,
      ItemsCount: [5, 10, 25, 50, 500],
      usersURL: "/user/allUsers",
      type: ""
    };
  },
  beforeMount() {
    this.getLogs();
  },
  watch: {
    itemsPerPage: function() {
      this.getLogs();
    },
    page: function() {
      if (this.page > 0) this.getLogs();
    },
    userType: function() {
      if (this.userType == "All") {
        this.usersURL = "/user/allUsers";
        this.type = "";
      }
      if (this.userType == "Individual") {
        this.usersURL = "/user/allIndividuals";
        this.type = "Individual";
      }
      if (this.userType == "Company") {
        this.usersURL = "/user/allCompanys";
        this.type = "Company";
      }
      if (this.userType == "Admin") {
        this.usersURL = "/user/allAdmins";
        this.type = "Admin";
      }
      this.getLogs();
    }
  },
  methods: {
    downloadFile() {
      this.appLoading = true;
      axios({
        url: "/user/pdfuserlist",
        method: "GET",
        // responseType: "blob",
        // headers: {
        //   "x-auth-token": localStorage.getItem("token")
        // },
        responseType: "blob"
      })
        .then(response => {
          this.appLoading = false;
          // this.msg = response.data.msg;
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "file.pdf");
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(err => {
          // console.log(err);
          this.appLoading = false;
          this.msg = err;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    },
    prevpage() {
      if (this.page > 1) this.page--;
    },
    nextpage() {
      if (this.page < this.totalpages) this.page++;
    },
    getLogs() {
      this.appLoading = true;
      axios({
        method: "GET",
        // url: "/user/allUsers",
        url: this.usersURL,
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        params: {
          count: this.itemsPerPage,
          page: this.page
        }
      })
        .then(response => {
          this.users = response.data.data;
          for (var i = 0; i < this.users.length; i++) {
            if (this.users[i].type == "Individual") {
              this.users[i].firstName = this.users[i].individual.firstName;
              this.users[i].lastName = this.users[i].individual.lastName;
              this.users[i].supportingEvidence = this.users[
                i
              ].individual.supportingEvidence;
              this.users[i].supportingEvidenceName = this.users[
                i
              ].individual.supportingEvidenceName;
              this.users[i].isAccreditated = this.users[
                i
              ].individual.isAccreditated;
              this.users[i].Type = this.users[i].type;
              this.users[i].companyName = null;
              this.users[i].registrationCountry = null;
              this.users[i].registrationNumber = null;
            }
            if (this.users[i].type == "Company") {
              this.users[i].isAccreditated = null;
              this.users[i].firstName = null;
              this.users[i].lastName = null;
              this.users[i].supportingEvidence = null;
              this.users[i].supportingEvidenceName = null;
              this.users[i].companyName = this.users[i].company.organisation;
              this.users[i].registrationCountry = this.users[
                i
              ].company.registrationCountry;
              this.users[i].registrationNumber = this.users[
                i
              ].company.registrationNumber;
            }
            var dt = new Date(this.users[i].accountCreateAt);
            dt = dt.toString();
            dt = dt.slice(4, 25);
            this.users[i].accountCreateAt = dt;
            dt = new Date(this.users[i].lastactiontime);
            dt = dt.toString();
            dt = dt.slice(4, 25);
            this.users[i].lastactiontime = dt;
            if (this.users[i].address) {
              this.users[i].address1 = this.users[i].address.address;
              this.users[i].address2 = this.users[i].address.address2;
              this.users[i].address3 = this.users[i].address.address3;
              this.users[i].city = this.users[i].address.city;
              this.users[i].postcode = this.users[i].address.postcode;
              this.users[i].Type = this.users[i].type;
            }
            delete this.users[i].individual;
            delete this.users[i].comapny;
            delete this.users[i].documents;
            delete this.users[i].address;
            delete this.users[i].__v;
            delete this.users[i].log;
            delete this.users[i].token;
            delete this.users[i].company;
            delete this.users[i]._id;
          }
          this.totalpages = response.data.pages;
          this.appLoading = false;
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.msg = "Something Went Wrong";
          alert(this.msg);
          this.showSnackBar = true;
        });
    }
  }
};
</script>
<style>
@media only screen and (min-width: 800px) {
  #app_height {
    min-height: 80vh;
    min-width: 80vw;
  }
}
.centered-input input {
  text-align: center;
  height: 20px;
}
</style>